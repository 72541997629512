import React, { useState, useEffect, useMemo, useCallback, useRef } from "react";
import { gql, useQuery } from "@apollo/client";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import { GridChartsModule } from "@ag-grid-enterprise/charts";
import { Spin } from 'antd';
import Select from 'react-select';
import { type } from "jquery";
import { Modal, Button } from "react-bootstrap";
// import { useHistory } from "react-router-dom";
const formName=gql`
query FormsNames($teamId: String) {
  formsNames(team_id: $teamId) {
    form_id
    form_name
  }
}`
const formData=gql`
query FormData {
  formData {
    team_id
  }
}`
const GET_OPPORTUNITY_TYPES = gql`
  query OpportunityTypeByTeam {
    opportunityTypeByTeam {
      opp_type
    }
  }
`;
const oppsData = gql`
query GetTeamSettings($filter: OppFilterInput) {
  Opps(filter: $filter) {
    opp_key
    sisu_client_updated_ts
    opp_updated_ts
    opp_stage
    opp_agreement_signed_date
    fub_deal_created_ts
    opp_type
    opp_appt_date
    fub_deal_stage_name
    opp_appt_met_date
    fub_person_id
    sisu_client_created_ts
    opp_created_ts
    fub_deal_id
    opp_appt_disposition
    teamFubDealId
    team
    fub_deal_entered_stage_ts
    sisu_client_id
    opp_assigned_osa
    opp_isa
    opp_notes
    opp_address
    opp_agreement_expiration_date
    appt_set_entry_id
    fub_appt_start_time
    fub_original_appt_start_time
    disp_text_wait_timestamp
    appt_set_lead_type
    appt_set_platform
    disp_text_original_wait_timestamp
    opp_address2
    opp_city
    opp_postal_code
    opp_last_name
    opp_state
    previous_opp_stage
    CreateEntryId
    pipeline_entry_id
    opp_forecasted_close_date
    opp_under_contract_date
    appt_form_id
    form_id_entry_id
    opp_settlement_date
    CreateFormId
    appt_outcome
    external_system_key_buyer
    otc_property_id
    external_system_key_seller
    FormId_EntryIds
    formid_entryids
    opp_price
    opp_commission_percent
    fub_appt_id
    stage_id
    pipeline_id
    opp_custom_fields
    custom_fields
    create_date
  }

  GetFieldsforTeam{
    field_id
    field_name
    field_type
    input_type
    settings
    field_sub_type
    opportunity_field_id
  }
}`

const FormNames = gql`
  query FormsNames($teamId: String) {
    formsNames(team_id: $teamId) {
      form_id
      form_name
    }
  }
`;

export default function OpportunitysList() {

  const searchTermref = useRef("");
  const [dashboardcounts, setdashboardcounts] = useState({
    oppcount: 0,
    totalOppPrice: 0,
    totalCgi: 0,
  });
  // const [selectedOptions, setSelectedOptions] = useState([]);
  const { loading: loadingFormData, error: errorFormData, data: dataFormData } = useQuery(formData, {
  });
  const { loading: loadingFormNames, error: errorFormNames, data: dataFormNames } = useQuery(formName, {
    variables: {
      teamId: dataFormData?.formData?.team_id
    }
  });
  const selectedOptionsref = useRef([]);
  const [selectedForm, setSelectedForm] = useState({});
  const [rowData, setRowData] = useState([]);
  const [isTableDataProcessing, setIsTableDataProcessing] = useState(true);
  const [modalVisible, setModalVisible] = useState(false);
  const [entryLinks, setEntryLinks] = useState([]);
  // const [gridApi, setGridApi] = useState(null);
  // useref for gridRef
  const gridRef = useRef(null);
  const dateFormatter = (params) => {
    if (!params.value) return '';
    const parsedDate = new Date(Date.parse(params.value));
  
    // Check if the date is valid
    if (isNaN(parsedDate)) return '';

    // Format the date as DD/MM/YYYY
    const day = String(parsedDate.getDate()).padStart(2, '0');
    const month = String(parsedDate.getMonth() + 1).padStart(2, '0');
    const year = parsedDate.getFullYear();

    return `${month}/${day}/${year}`;
  };

  const dateTimeFormatter = (params) => {
    if (!params.value) return '';
    const parsedDate = new Date(Date.parse(params.value));
  
    // Check if the date is valid
    if (isNaN(parsedDate)) return '';

    // Format the date as DD/MM/YYYY
    const day = String(parsedDate.getDate()).padStart(2, '0');
    const month = String(parsedDate.getMonth() + 1).padStart(2, '0');
    const year = parsedDate.getFullYear();
    const hours = String(parsedDate.getHours()).padStart(2, '0');
    const minutes = String(parsedDate.getMinutes()).padStart(2, '0');
    const seconds = String(parsedDate.getSeconds()).padStart(2, '0');

    return `${month}/${day}/${year} ${hours}:${minutes}:${seconds}`;

  };

  const dateFilterParams = {
    filter: 'agDateColumnFilter',
    comparator: (filterDate, cellValue) => {
      if (!cellValue) return -1; 

      const cellDate = cellValue instanceof Date ? cellValue : new Date(cellValue);
      if (isNaN(cellDate.getTime())) return -1; 
      return cellDate.getTime() - filterDate.getTime();
    },
    browserDatePicker: true
  };
  
  const exportToCsv = () => {
    if (gridRef.current) {
      gridRef.current.api.exportDataAsCsv();
    }
  };

  // make columnDefs a usestate
  const [columnDefs, setColumnDefs] = useState([]);
  
  // ----- Default state of ag grid------
  const defaultColDef = {
    flex: 1,
    minWidth: 100,
    filter: true,
    sortable: true,
    resizable: true,
    floatingFilter: true,
    cellClass: 'ag-cellz',
    enableValue: true,
    enableRowGroup: true,
    enablePivot: true,
  };

  // ----- Design each column type------
  const columnTypes = useMemo(() => {
    return {
      agent: {
        minWidth: 240,
        cellStyle: { fontWeight: '500' },
      },
      action: {
        minWidth: 100,
      },
    };
  }, []);

  // ----- to edit the tool and sidebar------
  const sideBar = {
    toolPanels: [
      {
        id: "columns",
        labelDefault: "Columns",
        labelKey: "columns",
        iconKey: "columns",
        toolPanel: "agColumnsToolPanel",
        // toolPanelParams: {
        //   // suppressRowGroups: false,
        //   suppressValues: true,
        //   // suppressPivots: true,
        //   // suppressPivotMode: true,
        // },
      },
      {
        id: "filters",
        labelDefault: "Filters",
        labelKey: "filters",
        iconKey: "filter",
        toolPanel: "agFiltersToolPanel",
      },
    ],
  };


  const { data: dataTypes } = useQuery(GET_OPPORTUNITY_TYPES, {
    notifyOnNetworkStatusChange: true,
  });

  const { loading: formsLoading, data: formsData } = useQuery(FormNames, {
    variables: {
      teamId: "",
    },
  });

  function CustomGroupCellRenderer(params) {
    return params.value;
  }

  const onFilterChanged = async (event) => {
    let totalPrice = 0;
    let totalCgi = 0;
    let count = 0;
    gridRef.current?.api.forEachNodeAfterFilter((node) => {
      // console.log(node.data);
      if (!node.data) return;
      count += 1;
      if (typeof node.data.opp_price === 'number' && !isNaN(node.data.opp_price)) {
         totalPrice = parseFloat((node.data.opp_price + totalPrice).toFixed(2));
         if (typeof node.data.opp_commission_percent === 'number' && !isNaN(node.data.opp_commission_percent)) {
          const totalPriceWithCommission = node.data.opp_price * (node.data.opp_commission_percent);
          totalCgi = parseFloat((totalPriceWithCommission + totalCgi).toFixed(2));
      }
    }

    })
    setdashboardcounts({
      oppcount: count,
      totalOppPrice: totalPrice,
      totalCgi: totalCgi,
    });
  };

  const { loading, data } = useQuery(oppsData, {
    variables: {
      filter: {
        opp_type: [],
      }
    },
  });
  useEffect(()=>{
    if (data && Array.isArray(data.Opps)) {
      data.Opps.forEach((item) => {
        if (item.FormId_EntryIds && item.FormId_EntryIds.length > 0) {
          console.log("FormId_EntryIds has data:", item.FormId_EntryIds);
        }
      });
    }
  },[data])
  
  const handleFormEntryClick = (data) => {
    if (data.FormId_EntryIds) {
      const formattedEntries = data.FormId_EntryIds.map((item) => {
        const [formId, entryId] = item.split('_');
        const url = `/forms/${formId}/entries/${entryId}`;
        return { formId, entryId, url };
      });
      setEntryLinks(formattedEntries); // Set the entries as clickable links
      setModalVisible(true); // Show the modal
    }
  
  
    
  }
  // const handleCloseModal = () => {
  //   setModalVisible(false);
  //   setEntryLinks([]);
  // };
  useEffect(() => {
    // append other columns to columnDefs from oppsData.GetFieldsforTeam
    console.log("Data Change", data?.Opps);
    if (data) {
      let columnDefs = [
        {
          headerName: "",
          type: "action",
          cellRenderer: "agGroupCellRenderer",
          cellRendererParams: {
            innerRenderer: (params) => (
              <i
                className="iconify pt-1 fs-5 pointer"
                data-icon="fluent:eye-24-regular"
              ></i>
            ),
          },
        },
        {
          headerName: "Agent Name", field: "opp_assigned_osa", type: 'agent', filter: 'agTextColumnFilter',
          filterParams: {
            applyMiniFilterWhileTyping: true,
            suppressMiniFilter: false,
          },
          // rowGroup: true
        },       
        {
          headerName: "Form Entries",
            field: "FormId_EntryIds",

          type: "action",
          // headerCheckboxSelection: true,
          // checkboxSelection:true,
          cellRenderer: "agGroupCellRenderer",
          cellRendererParams: {
            innerRenderer: (params) => (
              <button onClick={() => handleButtonClick(params?.data)}>
              {params?.data?.formid_entryids?.length == null ? "N/A" : `View ${params?.data?.formid_entryids?.length || 0} Entries`}
            </button>           ),
          },
        },
      
        {
          headerName: "FUB Lead ID", field: "fub_person_id", filter: 'agTextColumnFilter',
          filterParams: {
            applyMiniFilterWhileTyping: true,
            suppressMiniFilter: false,
          },
        },
        // filter: false because we already have opp type filter as external filter
        {
          headerName: "Opportunity Type", field: "opp_type", filter: false
        },
        {
          headerName: "FUB Deal ID", field: "fub_deal_id", filter: 'agTextColumnFilter',
          filterParams: {
            applyMiniFilterWhileTyping: true,
            suppressMiniFilter: false,
    
          },
        },
        {
          headerName: "Title", field: "opp_last_name", filter: 'agTextColumnFilter',
          filterParams: {
            applyMiniFilterWhileTyping: true,
            suppressMiniFilter: false,
            debounceMs: 500,
          },
        },
        { headerName: "Stage", field: "opp_stage" },
        {
          headerName: "Agreement Signed Date",
          field: "opp_agreement_signed_date",
          valueFormatter: dateFormatter,
          filter: 'agDateColumnFilter',
          filterParams: dateFilterParams
        },
        {
          headerName: "Appt Date",
          field: "opp_appt_date",
          valueFormatter: dateFormatter,
          filter: 'agDateColumnFilter',
          filterParams: dateFilterParams,
          headerTooltip: "Appointment Date of opportunity or lead"
        },
        {
          headerName: "Create Date",
          field: "opp_created_ts",                
          valueFormatter: dateTimeFormatter,        
          filter: 'agDateColumnFilter',         
          filterParams: dateFilterParams,       
          headerTooltip: "Creation timestamp formatted as YYYY-MM-DD HH:MM:SS",
        },
        {
          headerName: "Appt Met Date",
          field: "opp_appt_met_date",
          valueFormatter: dateFormatter,
          filter: 'agDateColumnFilter',
          filterParams: dateFilterParams
          // valueFormatter: (params) => formatDate(params.value),
        },
        {
          headerName: "Price", field: "opp_price", filter: 'agNumberColumnFilter',
          filterParams: {
            applyButton: true,
            clearButton: true,
            inRangeInclusive: true,
          }, cellStyle: params => params.value > 80 ? { color: 'green' } : { color: 'black' },
          aggFunc: "sum"
        },
        {
          headerName: "Sisu Client ID", field: "sisu_client_id", filter: 'agTextColumnFilter',
          filterParams: {
            applyMiniFilterWhileTyping: true,
            suppressMiniFilter: false,
          },
        },
        {
          headerName: "Agreement Expiration Date",
          field: "opp_agreement_expiration_date",
          valueFormatter: dateFormatter,
          filter: 'agDateColumnFilter',
          filterParams: dateFilterParams
          // valueFormatter: (params) => formatDate(params.value),
        },
        {
          headerName: "Commission Percent", field: "opp_commission_percent", filter: 'agNumberColumnFilter',
          filterParams: {
            applyButton: true,
            clearButton: true,
            inRangeInclusive: true,
          }
        },
        { headerName: "Commission Point", field: "custom_commission_point" },
        {
          headerName: "Postal Code", field: "opp_postal_code", filter: 'agTextColumnFilter',
          filterParams: {
            applyMiniFilterWhileTyping: true,
            suppressMiniFilter: false,
          },
        },
        {
          headerName: "Last Name", field: "opp_last_name", filter: 'agTextColumnFilter',
          filterParams: {
            applyMiniFilterWhileTyping: true,
            suppressMiniFilter: false,
          },
        },
        { headerName: "State", field: "opp_state" },
        {
          headerName: "Forecasted Close Date",
          field: "opp_forecasted_close_date",
          valueFormatter: dateFormatter,
          filter: 'agDateColumnFilter',
          filterParams: dateFilterParams
          // valueFormatter: (params) => formatDate(params.value),
        },
        {
          headerName: "Under Contract Date",
          field: "opp_under_contract_date",
          valueFormatter: dateFormatter,
          filterParams: dateFilterParams
          // valueFormatter: (params) => formatDate(params.value),
        },
        {
          headerName: "Settlement Date",
          field: "opp_settlement_date",
          valueFormatter: dateFormatter,
          filter: 'agDateColumnFilter',
          filterParams: dateFilterParams
          // valueFormatter: (params) => formatDate(params.value),
        },
      ];
      const fields = data.GetFieldsforTeam;
      fields.forEach(field => {
        // skip fields that are already in columnDefs
        let already_present = ["opp_assigned_osa", "fub_person_id", "fub_deal_id", "opp_last_name", "opp_stage", 
                                "opp_type", "opp_appt_date", "opp_appt_met_date", "opp_price", "sisu_client_id",
                                "opp_agreement_expiration_date", "opp_commission_percent", "opp_postal_code",
                                "opp_state", "opp_forecasted_close_date", "opp_under_contract_date", "opp_settlement_date"];
        let opportunity_field_id = field.opportunity_field_id;
          
        if (field.field_type == 'default'){
          opportunity_field_id = opportunity_field_id.split('_').slice(1).join('_');
        }
        if (already_present.includes(opportunity_field_id)) {
          return;
        } 
        columnDefs.push({
          headerName: field.field_name,
          field: opportunity_field_id,
          filter: 'agTextColumnFilter',
          filterParams: {
            applyMiniFilterWhileTyping: true,
            suppressMiniFilter: false,
          },
        });
      });
      setColumnDefs(columnDefs);
      setIsTableDataProcessing(false); // Set loading to false once column data is fetched
    }
  }, [data])

  const filteredOpps = useMemo(() => {
    let opps = data?.Opps;
    let totalPrice = 0;
    let totalCgi = 0;
    let count = 0;
    if (opps) {
      opps.forEach(opp => {
        count += 1;
        if (typeof opp.opp_price === 'number' && !isNaN(opp.opp_price)) {
          totalPrice = parseFloat((opp.opp_price + totalPrice).toFixed(2));
          if (typeof opp.opp_commission_percent === 'number' && !isNaN(opp.opp_commission_percent)) {
            const totalPriceWithCommission = opp.opp_price * (opp.opp_commission_percent);
            totalCgi = parseFloat((totalPriceWithCommission + totalCgi).toFixed(2));
          }
        }
      });
    }
    setdashboardcounts({
      oppcount: count,
      totalOppPrice: totalPrice,
      totalCgi: totalCgi,
    });
    return opps;
  }, [data]);

  function currencyConvert(totalOppPrice) {
    if (totalOppPrice >= 1000000000) {
      return (totalOppPrice / 1000000000).toFixed(2) + ' B';
    } else if (totalOppPrice >= 1000000) {
      return (totalOppPrice / 1000000).toFixed(2) + ' M';
    } else {
      return totalOppPrice?.toString();
    }
  }

  const resetFilters = () => {
    if (gridRef.current) {
      gridRef.current.api.setFilterModel(null);
    }
  };

  useEffect(() => {
    const preparedData = Array.isArray(filteredOpps) ? filteredOpps.map(opp => ({
      ...opp,
      ...opp.opp_custom_fields
    })) : [];
    setRowData(preparedData);
  }, [filteredOpps]);

  useEffect(() => {
    if (gridRef.current) {
      gridRef.current?.api.onFilterChanged();
    }
  }, [selectedForm, gridRef]);

  const isExternalFilterPresent = useCallback(() => {
    return !!selectedForm || selectedOptionsref.current.length > 0 || searchTermref.current.length > 0;
  }, [selectedForm, selectedOptionsref.current, searchTermref.current]);

  const doesExternalFilterPass = useCallback((node) => {
    if (!node.data) return true;
  
    const opp_type_options_length = selectedOptionsref.current.length;
    const search_term_length = searchTermref.current.length;
    const { formid_entryids } = node.data;
  
    // Check for `opp_type` filter
    if (opp_type_options_length > 0 && !selectedOptionsref.current.includes(node.data.opp_type)) {
      return false;
    }
  
    // Check for `search_term` filter
    if (search_term_length > 0) {
      const dataString = JSON.stringify(node.data).toLowerCase();
      if (!dataString.includes(searchTermref.current.toLowerCase())) {
        return false;
      }
    }
  
    // Check for `selectedForm` filter
    if (selectedForm?.value && formid_entryids) {
      if(formid_entryids.some(entry => entry.split('_')[0] === selectedForm?.value)){
        console.log("////matched", formid_entryids.some(entry => entry.split('_')[0], selectedForm))
      }
      return formid_entryids.some(entry => entry.split('_')[0] === selectedForm?.value);
    }
    else if (selectedForm?.value && !formid_entryids) {
      return false;
    }
    return true;
  }, [selectedOptionsref.current, searchTermref.current, selectedForm?.value]);
  
  const handle_search_term_change = (e) => {
    let new_search_term = e.target.value;
    if (new_search_term !== searchTermref.current) {
    searchTermref.current = e.target.value
    gridRef.current?.api.onFilterChanged();
    }
  };

  const handle_opp_type_options_change = useCallback((selected) => {
    let newvalues =  selected ? selected.map(option => option.value) : [];
    if (newvalues !== selectedOptionsref.current) {
        selectedOptionsref.current = newvalues;
        gridRef.current?.api.onFilterChanged();
    }
  }
  , [selectedOptionsref.current]);
  const styles = {
    modal: {
      position: 'fixed',
      top: '0',
      left: '0',
      right: '0',
      bottom: '0',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      zIndex: '1000',
    },
  
    entryLink: {
      margin: '10px 0', // Adds space between each entry link
    },
    linkText: {
      color: 'blue', // Changes link color
      textDecoration: 'underline', // Adds underline to links
    },
    modalContent: {
      position: 'relative', // So the close button is positioned relative to the content
      padding: '20px',
      backgroundColor: '#fff',
      borderRadius: '8px',
      width: '300px',
      margin: '0 auto',
    },
    closeButton: {
      position: 'absolute',
      top: '10px',
      right: '10px',
      background: 'none',
      border: 'none',
      fontSize: '24px',
      fontWeight: 'bold',
      cursor: 'pointer',
    }
  };
  const [showModal, setShowModal] = useState(false);
  // const [entryLinks, setEntryLinks] = useState([]);

  // const history = useHistory(); // For page navigation

  const handleButtonClick = (data) => {
    if(data?.formid_entryids) {  
      const formattedEntries = data?.formid_entryids?.map((item) => {
        const [formId, entryId] = item?.split('_');
        console.log("FormId_EntryIds", formId, entryId);
        const url = `/forms/${formId}/entries/${entryId}`;
        const Formname = dataFormNames?.formsNames.find(
          (form) => form?.form_id == formId // loose equality comparison
        );


        return { formId, entryId, url, formName: Formname?.form_name || "Test" };
      });
      setEntryLinks(formattedEntries); 
    setShowModal(true); // Show the modal when the button is clicked
    }
  };

  const handleRedirect = () => {
    setShowModal(false); // Close the modal
    // history.push('/new-page'); // Redirect to another page
  };

  const handleCloseModal = () => {
    setShowModal(false); // Close the modal without redirecting
  };
// console.log("formattedEntries", entryLinks,dataFormNames) 
  return (
    <>
      {/* <OppsNavBar /> */}
      <div>
      {showModal && (
  <div style={styles.modal}>
    <div style={styles.modalContent}>
    <button onClick={handleCloseModal} style={styles.closeButton}>×</button>

    {
        entryLinks.map((entry, index) => (
          <div key={index} style={styles.entryLink}>
            <span style={{paddingRight:"11px"}}>{entry?.formName}</span>
            <a href={entry.url} target="_blank" rel="noreferrer" style={styles.linkText}>
              Click to go to entry
            </a>
          </div>
        ))
      }
    </div>
  </div>
)}
        <section className="container-lg container-fluid pt-5">

          <section className="d-flex flex-column py-4 gap-3">
            <h5 className="m-0 fs-5 fw-bold">Opportunity list</h5>
            <p className="text-secondary">View and edit all your opportunity list and customize your data.</p>

            <div className="d-md-flex align-items-center">

              <div className="col-md-5 col-lg-5 col-xxl-4">
                <label className="small mb-2">Search</label>
                <input
                  className="form-control input-bn search-fgy mb-3"
                  placeholder="search opportunities"
                  value={searchTermref.current}
                  onChange={handle_search_term_change}
                />
              </div>

                  <div className="col-md-4 col-lg-4 col-xxl-4 px-4">
                    <label className="small mb-2">Opportunity Type</label>
                    <div className="input-group mb-3 w-100">
                    <Select
                      value={selectedOptionsref.current.map(option => ({
                        value: option,
                        label: option,
                      }))}
                      onChange={handle_opp_type_options_change}
                      isMulti={true}
                      options={dataTypes?.opportunityTypeByTeam.filter(type => type.opp_type !== "").map((type) => ({
                        value: type.opp_type,
                        label: type.opp_type
                      }))}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      placeholder="Select opportunity type"
                    />
                    </div>
                  </div>

                  <div className="col-md-4 col-lg-4 col-xxl-4 px-4">
                    <label className="small mb-2">Form Type</label>
                    <div className="input-group mb-3 w-100">
                    <Select
                      placeholder={`${formsLoading? "Loading forms" :"Select form type"}`}
                      onChange={(selected)=> { setSelectedForm(selected) }}
                      options={formsData?.formsNames?.map((form) => ({
                        value: form.form_id,
                        label: form.form_name
                      })) || []}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      aria-label="Select form type"
                      isClearable={true}
                    />
                    </div>
                  </div>

            </div>
            <div className="position-relative">
            <div className="row row-cols-3 py-3">

              <div>
                <div className="bg-white border rounded-3 py-3 px-4 text-center">

                  <span className="text-primary fw-bold">Counts</span>
                  <h2>{dashboardcounts.oppcount}</h2>
                  <span className="small text-secondary">{dashboardcounts.oppcount} after split Counts</span>

                </div>
              </div>

              <div>
                <div className="bg-white border rounded-3 py-3 px-4 text-center">

                  <span className="text-primary fw-bold">Total Volume</span>
                  <h2>$ {currencyConvert(dashboardcounts.totalOppPrice)}</h2>
                  <span className="small text-secondary">{dashboardcounts.totalOppPrice?.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</span>

                </div>
              </div>

              <div>
                <div className="bg-white border rounded-3 py-3 px-4 text-center">

                  <span className="text-primary fw-bold">Total GCI</span>
                  <h2>$ {currencyConvert(dashboardcounts.totalCgi)}</h2>
                  <span className="small text-secondary">{dashboardcounts.totalCgi?.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</span>

                </div>
              </div>
            </div>
              {loading && (
                <div className="loading-overlay">
                  <div className="spinner-border text-primary" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </div>
                </div>
              )}
            </div>

            <div className="w-100">
              <section className="w-100 mb-5 px-0">
                <button onClick={resetFilters}>Reset Filters</button>
                <button onClick={exportToCsv}>Export to CSV</button>
                <div className="ag-theme-quartz" style={{ height: 700 }}>
                {isTableDataProcessing? (
                  <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50%' }}>
                    <Spin size="large" />
                  </div>
                  ) : (
                  <AgGridReact
                    ref={gridRef}
                    rowData={rowData}
                    columnDefs={columnDefs}
                    columnTypes={columnTypes}
                    defaultColDef={defaultColDef}
                    modules={[GridChartsModule]}
                    frameworkComponents={{
                      agGroupCellRenderer: CustomGroupCellRenderer,
                    }}
                    sideBar={sideBar}
                    enableRangeSelection={true}
                    enableCharts={true}
                    headerHeight={40}
                    rowHeight={30}
                    rowSelection={"multiple"}
                    rowGroupPanelShow={"always"}
                    pagination={true}
                    paginationPageSizeSelector={[50, 100, 200]}
                    paginationPageSize={50}
                    tooltipShowDelay={500}
                    tooltipHideDelay={500}
                    pivotMode={false}
                    pivotPanelShow={"always"}
                    isExternalFilterPresent={isExternalFilterPresent}
                    doesExternalFilterPass={doesExternalFilterPass}
                    onFilterChanged={onFilterChanged}
                  />
              )}
                </div>
              </section>
            </div>
            
       
      

          </section>
        </section>
      </div>

    </>
  );
}
